<template>
  <v-dialog
    :value="value"
    max-width="1200"
    width="1200"
    persistent>
    <v-card tile flat>
      <v-toolbar flat>
        <v-toolbar-title>
          <v-card-title>
            Linked Items
          </v-card-title>
        </v-toolbar-title>
        <v-select
          v-model="groupId"
          label="Item Group"
          :items="groups"
          item-text="group_name"
          item-value="id"
          :menu-props="{ offsetY: true }"
          style="max-width: 35%"
          class="ml-2"
          hide-details
          outlined
          dense>
        </v-select>
        <v-spacer></v-spacer>
        <v-btn
          small
          text
          class="ml-4"
          @click="$emit('cancel')">
          Close
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
       <v-container fluid class="ma-0 pa-0">
        <v-data-table
          :height="tableSize"
          :headers="headers"
          :items.sync="relatedItems"
          :items-per-page="-1"
          :footer-props="{'items-per-page-options': [-1]}"
          :loading="loading"
          fixed-header
          calculate-widths
          sort-by="item_id"
          dense>
          <template v-slot:[`item.included`]="{ item }">
            <template v-if="!isIncluded(item)">
              <v-icon color="error">
                mdi-close
              </v-icon>
            </template>
            <v-icon v-else color="success">
              mdi-check-circle-outline
            </v-icon>
          </template>
        </v-data-table>
       </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
// API
import ItemGroups from '@/axios/item-group-endpoint'
// Mixins
import { displayAlert } from '@/mixins/alert'
import { fullWidthTable } from '@/mixins/table'
export default {
  data () {
    return {
      nonTableHeight: 350,
      tableSize: '',
      relatedItems: [],
      groups: [],
      groupId: null,
      loading: false,
      headers: [
        { text: 'On Contract', align: 'center', value: 'included', sortable: false, filterable: false, class: 'accent white--text' },
        { text: 'Source', sortable: false, value: 'source_name', class: 'accent white--text' },
        { text: 'Item Id', sortable: false, value: 'item_id', class: 'accent white--text' },
        { text: 'Description', sortable: false, value: 'item.description', class: 'accent white--text' },
        { text: 'UPC', sortable: false, value: 'item.upc', class: 'accent white--text' },
        { text: 'Vendor Id', sortable: false, value: 'item.vendor_id', class: 'accent white--text' },
        { text: 'Vendor Name', sortable: false, value: 'item.vendor_name', class: 'accent white--text' },
        { text: 'Retail Price', sortable: false, value: 'item.sale_price', class: 'accent white--text' },
        { text: 'Cost', sortable: false, value: 'item.cost', class: 'accent white--text' },
        { text: 'Pack Size', sortable: false, value: 'item.pack_size', class: 'accent white--text' }
      ]
    }
  },
  name: 'ContractLinkedItems',
  mixins: [displayAlert, fullWidthTable],
  props: {
    value: Boolean,
    item: Object,
    currentItems: Array
  },
  watch: {
    groupId: {
      handler (newValue) {
        if (newValue != null) {
          this.getRelatedItems()
        }
      }
    }
  },
  async created () {
    this.tableSize = window.innerHeight - this.nonTableHeight
    if (this.item) {
      await this.getItemGroups()
    }
  },
  methods: {
    async getItemGroups () {
      const item = this.item
      this.loading = true
      let groups = []
      try {
        const res = await ItemGroups.getGroupsByItem(item.source, item.item_id)
        if (res?.data?.length > 0) {
          groups = res.data.filter(group => group?.group_type?.constant !== 'COMPONENT_ITEM')
        }
        this.groups = groups
        if (this.groups.length > 0) {
          if (!this.groupId) this.groupId = this.groups[0].id
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    async getRelatedItems () {
      if (!this.groupId) return
      this.loading = true
      let relatedItems = []
      try {
        for await (let results of ItemGroups.getGroupItems(this.groupId)) {
          if (results?.length > 0) {
            results = results.flatMap(groupItem => {
              return this.formatGroupItem(groupItem)
            })
            relatedItems.push(...results)
          }
        }
        this.relatedItems = relatedItems
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    formatGroupItem (groupItem) {
      groupItem.source_name = this.$store.getters.getSourceName(groupItem.source)
      groupItem.included = this.isIncluded(groupItem)
      if (groupItem?.item) {
        groupItem.item.cost = `$${this.$config.formatCurrency(groupItem.item.cost)}`
        groupItem.item.sale_price = `$${this.$config.formatCurrency(groupItem.item.sale_price)}`
      }
      return groupItem
    },
    isSameItem (item1, item2) {
      return (item1?.item_id === item2.item_id) && (item1?.source === item2.source)
    },
    isIncluded (item) {
      return this.currentItems.some(i => {
        return this.isSameItem(i, item)
      })
    }
  }
}
</script>