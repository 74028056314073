var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.value,
        "max-width": "1200",
        width: "1200",
        persistent: ""
      }
    },
    [
      _c(
        "v-card",
        { attrs: { tile: "", flat: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "" } },
            [
              _c(
                "v-toolbar-title",
                [_c("v-card-title", [_vm._v(" Linked Items ")])],
                1
              ),
              _c("v-select", {
                staticClass: "ml-2",
                staticStyle: { "max-width": "35%" },
                attrs: {
                  label: "Item Group",
                  items: _vm.groups,
                  "item-text": "group_name",
                  "item-value": "id",
                  "menu-props": { offsetY: true },
                  "hide-details": "",
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.groupId,
                  callback: function($$v) {
                    _vm.groupId = $$v
                  },
                  expression: "groupId"
                }
              }),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  attrs: { small: "", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v(" Close ")]
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-container",
            { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
            [
              _c("v-data-table", {
                attrs: {
                  height: _vm.tableSize,
                  headers: _vm.headers,
                  items: _vm.relatedItems,
                  "items-per-page": -1,
                  "footer-props": { "items-per-page-options": [-1] },
                  loading: _vm.loading,
                  "fixed-header": "",
                  "calculate-widths": "",
                  "sort-by": "item_id",
                  dense: ""
                },
                on: {
                  "update:items": function($event) {
                    _vm.relatedItems = $event
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.included",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          !_vm.isIncluded(item)
                            ? [
                                _c("v-icon", { attrs: { color: "error" } }, [
                                  _vm._v(" mdi-close ")
                                ])
                              ]
                            : _c("v-icon", { attrs: { color: "success" } }, [
                                _vm._v(" mdi-check-circle-outline ")
                              ])
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }